import React, { useState, useEffect } from "react";
import "../../../assets/css/styles.min.css";
import "../../../assets/fonts/fontawesome-all.min.css";
import "../../../assets/fonts/font-awesome.min.css";
import "../styles-used.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DashboardOperateurGerance() {
  // Bar Chart
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: [
        "ENREGISTER",
        "EN ATTENTE",
        "PROGRAMER",
        "EN COURS",
        "TRANSFERER",
        "REGLER",
      ],
      datasets: [
        {
          label: "AEP",
          backgroundColor: "rgb(47, 115, 182, 0.7)",
          data: [2000, 10000, 5000, 15000, 10000, 20000],
        },
        {
          label: "ASS",
          backgroundColor: "rgb(118, 189, 128, 0.7)",
          data: [6000, 8000, 1200, 9000, 23000, 5000],
        },
        {
          label: "COM",
          backgroundColor: "rgb(38, 170, 224, 0.7)",
          data: [1000, 12000, 23000, 3000, 1200, 6000],
        },
      ],
    });
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
        },
      },
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex justify-content-between align-items-center mb-4">
          <h3 className=" mb-4 font-text-title">TABLEAU DE BORD</h3>
        </div>

        <div className="row">
          <div className="col-md-6 col-xl-3 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="text-uppercase text-primary fw-bold text-xs mb-1 text-center">
                      <span className="text-body">réclamation enregister</span>
                    </div>
                    <div className="text-dark fw-bold h5 mb-0">
                      <span className="d-lg-flex justify-content-lg-center font-text-title">
                        6670
                      </span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-save fa-2x text-gray-300 icon-dashboard-color"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-4">
            <div className="card shadow border-start-success py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="text-uppercase text-success fw-bold text-xs mb-1 text-center">
                      <span className="text-body">réclamation régler</span>
                    </div>
                    <div className="text-dark fw-bold h5 mb-0">
                      <span className="d-lg-flex justify-content-lg-center font-text-title">
                        2665
                      </span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-check-square fa-2x text-gray-300 icon-dashboard-color"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="text-uppercase text-primary fw-bold text-xs mb-1 text-center">
                      <span className="text-body">réclamation en attente</span>
                    </div>
                    <div className="text-dark fw-bold h5 mb-0">
                      <span className="d-lg-flex justify-content-lg-center font-text-title">
                        435
                      </span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-sort-amount-down fa-2x text-gray-300 icon-dashboard-color"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="text-uppercase text-primary fw-bold text-xs mb-1 text-center">
                      <span className="text-body">réclamation programé</span>
                    </div>
                    <div className="text-dark fw-bold h5 mb-0">
                      <span className="d-lg-flex justify-content-lg-center font-text-title">
                        1309
                      </span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300 icon-dashboard-color"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="text-uppercase text-primary fw-bold text-xs mb-1 text-center">
                      <span className="text-body">réclamation en cours</span>
                    </div>
                    <div className="text-dark fw-bold h5 mb-0">
                      <span className="d-lg-flex justify-content-lg-center font-text-title">
                        375
                      </span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300 icon-dashboard-color"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="text-uppercase text-primary fw-bold text-xs mb-1 text-center">
                      <span className="text-body">réclamation transférer</span>
                    </div>
                    <div className="text-dark fw-bold h5 mb-0">
                      <span className="d-lg-flex justify-content-lg-center font-text-title">
                        6675
                      </span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-share-alt fa-2x text-gray-300 icon-dashboard-color"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-7 col-xl-8">
            <Bar options={chartOptions} data={chartData} />
          </div>
          <div className="col-lg-5 col-xl-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash mt-ds">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="text-uppercase text-primary fw-bold text-xs mb-1 text-center">
                      <span className="table-title">Recherche rapide</span>
                      <div className="row mt-3 mb-3">
                        <div className="col col-8">
                          <input
                            className="form-control font-text-paragraphe"
                            type="search"
                            placeholder="N° de la reclamation"
                          />
                        </div>
                        <div className="col col-4">
                          <button
                            className="btn btn-light font-text-paragraphe"
                            type="button"
                          >
                            Rechercher
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-dark fw-bold h5 mb-0 ">
                      <span className="d-lg-flex mb-2">
                        Numero de contract :{" "}
                        <span className="color-rs">68282920972</span>
                      </span>
                      <span className="d-lg-flex mb-2">
                        Nom, Pénom du client :{" "}
                        <span className="color-rs">Jon Smith</span>
                      </span>
                      <span className="d-lg-flex mb-2">
                        N° de Réclamation : <span className="color-rs">3</span>
                      </span>
                      <span className="d-lg-flex mb-2">
                        Type : <span className="color-rs">Priorité</span>
                      </span>
                      <span className="d-lg-flex mb-2">
                        Téléphone :{" "}
                        <span className="color-rs">0656 678 756</span>
                      </span>
                      <span className="d-lg-flex mb-2">
                        Région : <span className="color-rs">ouest</span>
                      </span>
                      <span className="d-lg-flex mb-2">
                        Adresse :{" "}
                        <span className="color-rs">
                          Rue les frére chemloul, Oran
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DashboardOperateurGerance;
