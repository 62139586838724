import React from "react";

import AppAgentSaisie from "./AppAgentSaisie";
import LoginPage from "./UI/auth/LoginPage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AppOperateurGerance from "./AppOperateurGerance";
import ReseauAEP from "./UI/component/operateur_reseau_aep/ReseauAEP";
import AppOperateurReseauAEP from "./AppOperateurReseauAEP";

function Principale() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={["/", "/login"]}
          exact
          component={() => (
            <>
              <LoginPage />
            </>
          )}
        />
      </Switch>
      <Switch>
        <Route path="/dashboard/agent_saisie">
          <AppAgentSaisie />
        </Route>
      </Switch>
      <Switch>
        <Route path="/dashboard/operateur_gerance">
          <AppOperateurGerance />
        </Route>
      </Switch>
      <Switch>
        <Route path="/dashboard/operateur_reseau_aep">
          <AppOperateurReseauAEP />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Principale;
