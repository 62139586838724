import React, { useState } from "react";
import "../../../assets/css/styles.min.css";
import "../styles-used.css";
import axios from "axios";
import "../../../utils/globalConfig";

function SearchReclamationAgentSaisie() {
  const [data, getData] = useState([]);
  const url = "https://seorrr.herokuapp.com/reclamations";
  

  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const [valuesearch, setValuesearch] = useState("");
  const handleChange = (event) => {
    setValuesearch(event.target.value);
    console.log("value is:", event.target.value);
  };

  const handleSearch = async () => {
    await axios.get(url.concat("/", valuesearch), config).then((response) => {
      //console.log(response.data);
      getData(response.data);
    });
  };

  console.log(data);

  return (
    <>
      <div className="container-fluid">
        <h3 className=" mb-4 font-text-title">RECHERCHE UNE RECLAMATION</h3>
        <div>
          <div className="row">
            <div className="col">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" for="username">
                          <span>Rechercher une réclamation</span>
                          <br />
                        </label>
                        <div className="row">
                          <div className="col col-8">
                            <input
                              className="form-control font-text-paragraphe"
                              type="search"
                              placeholder="N° de la reclamation"
                              onClick={handleSearch}
                              onChange={handleChange}
                              value={valuesearch}
                            />
                          </div>
                          <div className="col">
                            <button
                              className="btn btn-info link-light btn-ds-op btn-ds font-text-paragraphe"
                              type="button"
                              onClick={handleSearch}
                            >
                              Rechercher
                              <i class="bi bi-search icon-btn-ds"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" for="username">
                          <span>Etat de la reclamation</span>
                          <br />
                        </label>
                        <div className="row">
                          <div className="col">
                            <span className="badge bg-success text-uppercase fs-6 border rounded font-text-paragraphe">
                              {data.etatReclamation}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            N° de réclamation
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                          <div className="show-search-data">{data.id}</div>
                          
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Nom / Raison S<br />
                          </label>
                        </div>
                        <div className="col col-8">

                        <div className="show-search-data">
                      
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Date de réclamation
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">

                          <div className="show-search-data">{data.dateReclmation}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            N° contrat
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                          <div className="show-search-data">{data.numContrat}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Type de réclamation
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                          
                          <div className="show-search-data">{data.typeReclmationId}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Adresse
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                        <div className="show-search-data"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Direction concerné
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                        <div className="show-search-data"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Rue
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                         
                          <div className="show-search-data">{data.rue}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Observation
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                        
                          <div className="show-search-data">{data.observation}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Commune
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                          
                          <div className="show-search-data">{data.commune}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            N° de téléphone
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                          
                           <div className="show-search-data">{data.telephone}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Bloc
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                          
                          <div className="show-search-data">{data.bloc}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Email
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                          
                          <div className="show-search-data">{data.email}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Entree
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                      
                          <div className="show-search-data">{data.entree}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Niveau urgence
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                         
                           <div className="show-search-data"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Etage
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                         
                           <div className="show-search-data">{data.etage}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Responsable
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                         
                          <div className="show-search-data"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            Ail
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                          
                          <div className="show-search-data"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row rows-ds">
                    <div className="col"></div>
                    <div className="col">
                      <div className="row">
                        <div className="col col-4">
                          <label className="col-form-label d-lg-flex label-ds">
                            N° Dom
                            <br />
                          </label>
                        </div>
                        <div className="col col-8">
                         
                          <div className="show-search-data"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchReclamationAgentSaisie;