//import React, { lazy, Suspense } from "react";
import { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme/themes";
//import Footer from "./UI/base/footer/footer";
//Privet route
//Public route
import ScrollToTop from "./UI/other/ScrollToTop";
// success model valid
//import { useStorage } from "./context/useStorage";
import { Toaster } from "react-hot-toast";
import SideMenuAgentSaisie from "./UI/base/Slidebarre/SideMenuAgentSaisie";
import Footer from "./UI/base/footer/footer";

// added
import LoginPage from "./UI/auth/LoginPage";
import Deconnecter from "./UI/auth/Logout";
import DashboardAgentSaisie from "./UI/component/agent_saisie/DashboardAgentSaisie";
import ReclamationAgentSaisie from "./UI/component/agent_saisie/ReclamationAgentSaisie";
import AddReclamationAgentSaisie from "./UI/component/agent_saisie/AddReclamationAgentSaisie";
import AddReclamationTAgentSaisie from "./UI/component/agent_saisie/AddReclamationTAgentSaisie";
import SearchReclamationAgentSaisie from "./UI/component/agent_saisie/SearchReclamationAgentSaisie";
import Add from "./UI/component/agent_saisie/Add";

// for test

const Contact = () => {
  return <h1>Contact</h1>;
};

const Parametre = () => {
  return <h1>Parametre</h1>;
};

function AppAgentSaisie() {
  //const { isInitialized } = useStorage();
  const [inactive, setInactive] = useState(false);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          {/*         
          <Switch>
            <Route
              path="/"
              exact
              component={() => (
                <>
                
                  <LoginPage />
                </>
              )}
            />
          </Switch> */}

          <div className="row">
            <div className="col-2 col-sm-2">
              <SideMenuAgentSaisie
                onCollapse={(inactive) => {
                  setInactive(inactive);
                }}
              />
            </div>
            <div className="col-10 col-sm-10 ">
              <div className={`container ${inactive ? "inactive" : ""}`}>
                <Switch>
                  <Route
                    path="/dashboard/agent_saisie"
                    exact
                    component={() => (
                      <>
                        <DashboardAgentSaisie />
                      </>
                    )}
                  />

                  <Route
                    path="/dashboard/agent_saisie"
                    exact
                    component={() => (
                      <>
                        <DashboardAgentSaisie />
                      </>
                    )}
                  />

                  <Route
                    path="/reclamations/agent_saisie"
                    exact
                    component={() => (
                      <>
                        <ReclamationAgentSaisie />
                      </>
                    )}
                  />

                  <Route
                    path="/reclamations/ajouter_reclamation/agent_saisie"
                    exact
                    component={() => (
                      <>
                        <Add />
                      </>
                    )}
                  />

                  <Route
                    path="/reclamations/ajouter_reclamation_2/agent_saisie"
                    exact
                    component={() => (
                      <>
                        <AddReclamationTAgentSaisie />
                      </>
                    )}
                  />

                  <Route
                    path="/reclamations/rechercher_reclamation/agent_saisie"
                    exact
                    component={() => (
                      <>
                        <SearchReclamationAgentSaisie />
                      </>
                    )}
                  />

                  <Route
                    path="/contact"
                    exact
                    component={() => (
                      <>
                        <Contact />
                      </>
                    )}
                  />
                  <Route
                    path="/parametre"
                    exact
                    component={() => (
                      <>
                        <Parametre />
                      </>
                    )}
                  />
                  <Route
                    path="/deconnecter"
                    exact
                    component={() => (
                      <>
                        <Deconnecter />
                      </>
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </ThemeProvider>
        <Switch>
          <Route
            path={[
              "/dashboard/agent_saisie",
              "/reclamations/agent_saisie",
              "/reclamations/ajouter_reclamation/agent_saisie",
              "/reclamations/ajouter_reclamation_2/agent_saisie",
              "/reclamations/rechercher_reclamation/agent_saisie",
              "/contact",
              "/parametre",
              "/deconnecter",
            ]}
            exact
            component={() => (
              <>
                <Footer />
              </>
            )}
          ></Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default AppAgentSaisie;
