import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoginPage from "../auth/LoginPage";

export default function Logout() {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      history.push("/login");
      window.location.reload();
      localStorage.removeItem("role");
      localStorage.removeItem("token");
    })();
  });
  return <></>;
}
