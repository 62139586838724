//import React, { lazy, Suspense } from "react";
import { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme/themes";
//import Footer from "./UI/base/footer/footer";
//Privet route
//Public route
import ScrollToTop from "./UI/other/ScrollToTop";
// success model valid
//import { useStorage } from "./context/useStorage";
import { Toaster } from "react-hot-toast";
import SideMenuOperateurReseauAEP from "./UI/base/Slidebarre/SideMenuOperateurReseauAEP";
import Footer from "./UI/base/footer/footer";

// added
import LoginPage from "./UI/auth/LoginPage";
import Deconnecter from "./UI/auth/Logout";
import DashboardOperateurReseauAEP from "./UI/component/operateur_reseau_aep/DashboardOperateurReseauAEP";
import ReseauAEP from "./UI/component/operateur_reseau_aep/ReseauAEP";
import AlimentationReseauAEP from "./UI/component/operateur_reseau_aep/AlimentationReseauAEP";

// for test

const Simulateur = () => {
  return <h1>Simulateur</h1>;
};

const Contact = () => {
  return <h1>Contact</h1>;
};

const Parametre = () => {
  return <h1>Parametre</h1>;
};

function AppOperateurReseauAEP() {
  //const { isInitialized } = useStorage();
  const [inactive, setInactive] = useState(false);

  /*
  //const [showAgentSaisieBoard, setShowAgentSaisieBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      //setShowAgentSaisieBoard(user.roles.includes("ROLE_AGENT_SAISIE"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }
    EventBus.on("logout", () => {
      logOut();
    });
    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    //setShowAgentSaisieBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };
  */

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <Toaster />
          {/* <Switch>
            <Route
              path="/auth/login"
              exact
              component={() => (
                <>
                  <LoginPage />
                </>
              )}
            />
          </Switch> */}

          <div className="row">
            <div className="col-2">
              <SideMenuOperateurReseauAEP
                onCollapse={(inactive) => {
                  setInactive(inactive);
                }}
              />
            </div>
            <div className="col-10">
              <div className={`container ${inactive ? "inactive" : ""}`}>
                <Switch>
                  <Route
                    path="/dashboard/operateur_reseau_aep"
                    exact
                    component={() => (
                      <>
                        <DashboardOperateurReseauAEP />
                      </>
                    )}
                  />

                  <Route
                    path="/dashboard/operateur_reseau_aep"
                    exact
                    component={() => (
                      <>
                        <DashboardOperateurReseauAEP />
                      </>
                    )}
                  />

                  <Route
                    path="/reseau_aep/operateur_reseau_aep"
                    exact
                    component={() => (
                      <>
                        <ReseauAEP />
                      </>
                    )}
                  />

                  <Route
                    path="/alimentation/operateur_reseau_aep"
                    exact
                    component={() => (
                      <>
                        <AlimentationReseauAEP />
                      </>
                    )}
                  />

                  <Route
                    path="/simulateur/operateur_reseau_aep"
                    exact
                    component={() => (
                      <>
                        <Simulateur />
                      </>
                    )}
                  />

                  <Route
                    path="/contact"
                    exact
                    component={() => (
                      <>
                        <Contact />
                      </>
                    )}
                  />
                  <Route
                    path="/parametre"
                    exact
                    component={() => (
                      <>
                        <Parametre />
                      </>
                    )}
                  />
                  <Route
                    path="/deconnecter"
                    exact
                    component={() => (
                      <>
                        <Deconnecter />
                      </>
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </ThemeProvider>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default AppOperateurReseauAEP;
