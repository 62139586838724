import React from "react";
import "../../../assets/css/styles.min.css";
import "../styles-used.css";

function AddReclamationTAgentSaisie() {
  return (
    <>
      <div className="container-fluid">
        <h3 className=" mb-4 font-text-title">AJOUTER UNE RECLAMATION</h3>
        <form action="/agent_de_saisie/reclamation01.html">
          <div className="row mb-3">
            <div className="col">
              <div className="card shadow mb-3 d-none">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label ">
                          Canal d’enregistrement&nbsp;
                          <br />
                        </label>
                        <select className="form-select font-text-paragraphe">
                          <optgroup label="This is a group">
                            <option value="12" selected="">
                              CATO
                            </option>
                            <option value="13">This is item 2</option>
                            <option value="14">This is item 3</option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          Source d’avénement&nbsp;
                          <br />
                        </label>
                        <select className="form-select font-text-paragraphe">
                          <optgroup label="This is a group">
                            <option value="12" selected="">
                              Personne passagé
                            </option>
                            <option value="13">This is item 2</option>
                            <option value="14">This is item 3</option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label ">
                          N° de téléphone&nbsp;
                          <br />
                        </label>
                        <input
                          className="form-control font-text-paragraphe"
                          type="email"
                          id="email-1"
                          placeholder="N° de téléphone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" for="city">
                          Gérance&nbsp;
                          <br />
                        </label>
                        <select className="form-select font-text-paragraphe">
                          <optgroup label="This is a group ">
                            <option value="12" selected="">
                              Bir El Djir
                            </option>
                            <option value="13">This is item 2</option>
                            <option value="14">This is item 3</option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" for="country">
                          Commune&nbsp;
                          <br />
                        </label>
                        <select className="form-select font-text-paragraphe">
                          <optgroup label="This is a group ">
                            <option value="12" selected="">
                              Bir El Djir
                            </option>
                            <option value="13">This is item 2</option>
                            <option value="14">This is item 3</option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label" for="city">
                          Rue
                          <br />
                        </label>
                        <select className="form-select font-text-paragraphe">
                          <optgroup label="This is a group">
                            <option value="12" selected="">
                              70 LOGTS MOHAMED GHRISS PROMO STAM
                            </option>
                            <option value="13">This is item 2</option>
                            <option value="14">This is item 3</option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                    <div className="col d-lg-flex align-items-lg-center">
                      <div className="mb-3">
                        <label className="form-label lab-ps-dc" for="country">
                          Etat de rue
                          <br />
                        </label>
                        <span className="badge bg-success text-uppercase fs-6 border rounded font-text-paragraphe">
                          ACTIVE
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-3 card-pad-ds">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <label className="form-label">
                        Niveau d’urgence&nbsp;
                        <br />
                      </label>
                      <div className="mb-3">
                        <div className="d-flex">
                          <div className="form-check redio-sp-ds">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="formCheck-1"
                            />
                            <label
                              className="form-check-label"
                              for="formCheck-1"
                            >
                              Normal
                              <br />
                            </label>
                          </div>
                          <div className="form-check redio-sp-ds">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="formCheck-4"
                            />
                            <label
                              className="form-check-label"
                              for="formCheck-4"
                            >
                              Attention particulier
                              <br />
                            </label>
                          </div>
                          <div className="form-check redio-sp-ds">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="formCheck-3"
                            />
                            <label
                              className="form-check-label"
                              for="formCheck-3"
                            >
                              Urgent
                              <br />
                            </label>
                          </div>
                          <div className="form-check redio-sp-ds">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="formCheck-2"
                            />
                            <label
                              className="form-check-label"
                              for="formCheck-2"
                            >
                              Extreme urgence
                              <br />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-3 card-pad-ds">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label ">
                          Attacher des fichiers
                          <br />
                        </label>
                        <input
                          className="form-control font-text-paragraphe"
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col">
                    <button
                      className="btn btn-primary border rounded d-block btn-user w-100 font-text-paragraphe"
                      data-bss-hover-animate="pulse"
                      type="submit"
                    >
                      VALIDER
                      <br />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddReclamationTAgentSaisie;
