import React from "react";
import "../../../assets/css/styles.min.css";
import "../styles-used.css";

import { useEffect, useState } from "react";
import AddReclamationAgentSaisie from "./AddReclamationAgentSaisie";
import AddReclamationTAgentSaisie from "./AddReclamationTAgentSaisie";
import axios from "axios";

function Add() {
  const [source, setsource] = useState("client");
  const [clientvisible, setclientvisible] = useState(false);
  const [passagevisible, setpassagevisible] = useState(false);


  const handleOnChange = (e) => {
    setsource(e.target.value);
  };

  function submit(e) {
    e.preventDefault();
    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const dd = new Date();
    axios.post(
      url,
      {
        typeReclmationId: 1,
        numContrat: "100",
        idDir: 1,
        sourceId: 1,
        dateReclmation: dd,
        telephone: data.telephone,
        email: data.email,
        canalEnregistrementId: 1,
        etatReclamation: "ACTIVE",
        commentaire: data.commentaire,
        observation: "string",
      },
      config
    );
    console.log(config.headers.Authorization);
  }

  useEffect(() => {
    source === "client" ? setclientvisible(true) : setclientvisible(false);
    source === "passage" ? setpassagevisible(true) : setpassagevisible(false);
  }, [source]);

  const url = "https://seorrr.herokuapp.com/reclamations";
  const [data, setData] = useState({
    typeReclamationId: 1,
    numContrat: "100",
    idDir: 1,
    sourceId: 1,
    dateReclamation: "",
    telephone: "",
    email: "",
    canalEnregistrementId: null,
    etatReclamation: "ACTIVE",
    commentaire: "",
    observation: "",
  });

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  return (
    <div className="container-fluid">
      <h3 className="mb-4 font-text-title">AJOUTER UNE RECLAMATION</h3>
      <form onSubmit={(e) => submit(e)}>
        <div className="row mb-3">
          <div className="col">
            <div className="card shadow mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label ">
                        Canal d’enregistrement&nbsp;
                        <br />
                      </label>
                      <select
                        className="form-select font-text-paragraphe"
                        id="canalEnregistrementId"
                      >
                        <optgroup label="This is a group">
                          <option
                            onChange={(e) => handle(e)}
                            value="1"
                            selected=""
                            id="sourceId"
                          >
                            CATO
                          </option>
                          <option value="2">EMAIL</option>
                          <option value="3">FACEBOOK</option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label ">
                        Source d’avénement
                        <br />
                      </label>
                      <select
                        className="form-select font-text-paragraphe"
                        value={source}
                        onChange={handleOnChange}
                      >
                        <optgroup label="This is a group">
                          <option value="client" selected="">
                            Client particulier
                          </option>
                          <option value="passage">Passagé</option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">
                        N° de téléphone&nbsp;
                        <br />
                      </label>
                      <input
                        onChange={(e) => handle(e)}
                        className="form-control font-text-paragraphe"
                        id="telephone"
                        value={data.telephone}
                        placeholder="N° de téléphone"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {clientvisible && (
              <div>
                <div className="card shadow">
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="form-label" for="address">
                        Numéro du contrat
                        <br />
                      </label>
                      <input
                        className="form-control font-text-paragraphe"
                        id="numContrat"
                        onChange={(e) => handle(e)}
                        value={data.numContrat}
                        type="text"
                        placeholder="Numéro du contrat"
                      />
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="city">
                            Nom / Raison S<br />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="city"
                            placeholder="..."
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="country">
                            Commune
                            <br />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="country"
                            placeholder="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="city">
                            Adresse
                            <br />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="city-3"
                            placeholder="..."
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="country">
                            N° de téléphone
                            <br />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="country-3"
                            placeholder="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="city">
                            Email
                            <br />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="email"
                            onChange={(e) => handle(e)}
                            value={data.email}
                            placeholder="..."
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="country">
                            Nom de rue
                            <br />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="country-2"
                            placeholder="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col"></div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label lab-ps-dc" for="country">
                            Etat de rue
                            <br />
                          </label>
                          <span className="badge bg-success text-uppercase fs-6 border rounde font-text-paragraphe">
                            ACTIVE
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-3 card-pad-ds">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <label className="form-label">
                          Niveau d’urgence&nbsp;
                          <br />
                        </label>
                        <div className="mb-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option1"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Normal
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="option2"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              Attention particulier
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio3"
                              value="option3"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio3"
                            >
                              Urgent
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio4"
                              value="option4"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio4"
                            >
                              Extreme urgence
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-3 card-pad-ds">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label">
                            Commentaire
                            <br />
                          </label>
                          <textarea
                            className="form-control"
                            id="commentaire"
                            onChange={(e) => handle(e)}
                            value={data.commentaire}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-3 card-pad-ds">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label ">
                            Attacher des fichiers
                            <br />
                          </label>
                          <input
                            className="form-control font-text-paragraphe"
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {passagevisible && (
              <div>
                <div className="card shadow">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="city">
                            Gérance
                            <br />
                          </label>
                          <select className="form-select font-text-paragraphe">
                            <optgroup label="This is a group ">
                              <option value="12" selected="">
                                ARZEW
                              </option>
                              <option value="13">BITHIOUA</option>
                              <option value="14">BIR EL DJIR</option>
                              <option value="13">BOUTLELIS</option>
                              <option value="14">GDYEL</option>
                              <option value="13">ORAN</option>
                              <option value="14">OUED TELELAT</option>
                              <option value="13">SENIA</option>
                              <option value="14">AIN EL TURCK</option>
                            </optgroup>
                          </select>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="country">
                            Commune&nbsp;
                            <br />
                          </label>
                          <select className="form-select font-text-paragraphe">
                            <optgroup label="This is a group ">
                              <option value="12" selected="">
                                ARZEW
                              </option>
                              <option value="13">BITHIOUA</option>
                              <option value="14">BIR EL DJIR</option>
                              <option value="13">BOUTLELIS</option>
                              <option value="14">GDYEL</option>
                              <option value="13">ORAN</option>
                              <option value="14">OUED TELELAT</option>
                              <option value="13">SENIA</option>
                              <option value="14">AIN EL TURCK</option>
                            </optgroup>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label" for="city">
                            Rue
                            <br />
                          </label>
                          <select className="form-select font-text-paragraphe">
                            <optgroup label="This is a group">
                              <option value="12" selected="">
                                --
                              </option>
                            </optgroup>
                          </select>
                        </div>
                      </div>
                      <div className="col d-lg-flex align-items-lg-center">
                        <div className="mb-3">
                          <label className="form-label lab-ps-dc" for="country">
                            Etat de rue
                            <br />
                          </label>
                          <span className="badge bg-success text-uppercase fs-6 border rounded font-text-paragraphe">
                            ACTIVE
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-3 card-pad-ds">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <label className="form-label">
                          Niveau d’urgence&nbsp;
                          <br />
                        </label>
                        <div className="mb-3">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option1"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Normal
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="option2"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              Attention particulier
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio3"
                              value="option3"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio3"
                            >
                              Urgent
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio4"
                              value="option4"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio4"
                            >
                              Extreme urgence
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-3 card-pad-ds">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label ">
                            Attacher des fichiers
                            <br />
                          </label>
                          <input
                            className="form-control font-text-paragraphe"
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="row">
                <div className="col"></div>
                <div className="col">
                  <button
                    className="btn btn-primary border rounded d-block btn-user w-100 font-text-paragraphe"
                    data-bss-hover-animate="pulse"
                    type="submit"
                  >
                    VALIDER
                    <br />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Add;
