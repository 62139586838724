import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./side-menu.css";

export default function MenuItem(props) {
  const { menuName, subMenusName, iconClassName, onClick, to, exact } = props;
  const [expand, setExpand] = useState(false);
  return (
    <li onClick={props.onClick}>
      <NavLink
        exact={exact}
        to={to}
        onClick={() => setExpand(!expand)}
        className="menu-item"
      >
        <div className="menu-icon">
          <i className={iconClassName}></i>
        </div>
        <span>{menuName}</span>
      </NavLink>
      {subMenusName && subMenusName.length > 0 ? (
        <ul className={`sub-menu ${expand ? "active" : ""}`}>
          {subMenusName.map((manu, index) => (
            <li key={index}>
              <NavLink to={manu.to}>{manu.menuName}</NavLink>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
}
