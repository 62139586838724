import React from "react";
import "../../../assets/css/styles.min.css";

function FooterLogin() {
    var date = new Date();
    return (
        <>
            <div className="bg-white sticky-footer footer-ds-lg">
                <div className="container my-auto">
                    <div className="text-center my-auto copyright">
                        <p>La plateforme est privé, si vous vous inscrivez ou rencontrez un problème pour vous connecter au plateforme, <br /> merci de contacter les administrateurs ou le responsable du plateforme. Les données sur la plate-forme sont des données d'entreprise et ne peuvent pas être partagées</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FooterLogin;
