import React, { useState, useEffect, useRef } from "react";
import "../../../assets/css/styles.min.css";
import "../styles-used.css";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../../utils/globalConfig";

function ReclamationListOperateurGerance() {
  const [data, setData] = useState([]);
  const url = global.config.apiUrl.reclamation.link;

  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(url, config).then((response) => {
      setData(response.data);
    });
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total text-pagination">
      Affichage de {from} a {to} sur {size} Résultats
    </span>
  );

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "N° Reclamation",
    },
    {
      dataField: "etatReclamation",
      text: "Etat Reclamation",
    },
    {
      dataField: "Contrat.numContrat",
      text: "N° Contrat",
    },
    
    {
      dataField: "observation",
      text: "Observation",
    },
    {
      dataField: "sourceId",
      text: "SourceId",
    },
    {
      dataField: "geranceId",
      text: "GeranceId",
    },
    {
      dataField: "Contrat.nom",
      text: "Nom Client",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "dateReclmation",
      text: "Date Reclmation",
    },
  ];

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-light"}`}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const options = {
    sizePerPageRenderer,
    firstPageText: "Première",
    prePageText: "Retour",
    nextPageText: "suivant",
    lastPageText: "Dernier",
    nextPageTitle: "Première page",
    prePageTitle: "Pré-page",
    firstPageTitle: "Page suivante",
    lastPageTitle: "Dernière page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "Tout",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <div className="container-fluid">
        <h3 className="mb-4 font-text-title">Réclamations</h3>
        <div className="card shadow">
          <div className="card-body">
            <div
              className="table-responsive table mt-2"
              id="dataTable"
              role="grid"
              aria-describedby="dataTable_info"
            >
              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <div>
                    <SearchBar {...props.searchProps} />

                    <hr />
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      striped
                      hover
                      condensed
                      noDataIndication="Aucun résultat n'existe  ou une problem de connection internet !"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ReclamationListOperateurGerance;
