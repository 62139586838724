import "../../assets/css/styles.min.css";
import "../../UI/component/styles-used.css";
import bgseor from "../../images/bgseor.jpg";
import LogoSeor from "../../images/logo-seor/c-logo.png";
import FooterLogin from "../base/footer/FooterLogin";
// login form
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import Footer from "../base/footer/footer";

const required = (value) => {
  if (!value) {
    return <div className="invalid-feedback d-block">Ce champ est requis!</div>;
  }
};

function LoginPage() {
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const history = useHistory();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(email, password).then(
        () => {
          if (localStorage.getItem("role") === '"user"') {
            history.push("/dashboard/agent_saisie");

            window.location.reload();
          }
          if (localStorage.getItem("role") === '"oprgerance"') {
            history.push("/dashboard/operateur_gerance");

            window.location.reload();
          }
          if (localStorage.getItem("role") === '"reseauaep"') {
            history.push("/dashboard/operateur_reseau_aep");

            window.location.reload();
          }
          if (localStorage.getItem("role") === '"admin"') {
            window.location.href = "https://seorrr.herokuapp.com";
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////

  return (
    <div id="body-ds" className="bg-gradient-primary-login">
      <div className="container mt-150">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-12 col-xl-10">
            <div className="card shadow-lg o-hidden border-0 my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="p-5 align-items-center">
                      <div className="text-center">
                        <img className="logo-login" src={LogoSeor} />
                        <h1 className="text-dark mb-4" id="text-login">
                          <span className="font-text-title">Bienvenue !</span>
                          <p className="text-bv">
                            Bienvenue sur la plateforme de réclamations de la
                            société de l'eau et de l'assainissement d'Oran
                            (SEOR) | Algerie
                          </p>
                        </h1>
                      </div>

                      <Form className="user" onSubmit={handleLogin} ref={form}>
                        <div className="mb-3">
                          <Input
                            className="border rounded form-control form-control-user input-ds font-text-paragraphe"
                            type="email"
                            id="exampleInputEmail"
                            placeholder="Email"
                            name="email"
                            value={email}
                            onChange={onChangeEmail}
                            validations={[required]}
                          />
                        </div>

                        <div className="mb-3">
                          <Input
                            className="border rounded form-control form-control-user input-ds font-text-paragraphe"
                            type="password"
                            id="exampleInputPassword"
                            placeholder="Mots de pass"
                            name="password"
                            value={password}
                            onChange={onChangePassword}
                            validations={[required]}
                          />
                        </div>

                        <div className="mb-3">
                          <div className="custom-control custom-checkbox small">
                            <div className="form-check">
                              <Input
                                className="form-check-input custom-control-input"
                                type="checkbox"
                                id="formCheck-1"
                              />
                              <label className="form-check-label custom-control-label ">
                                Souviens-toi de moi
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <button
                            className="btn btn-primary border rounded d-block btn-user w-100 font-text-paragraphe"
                            disabled={loading}
                          >
                            <span>se connecter</span>
                          </button>

                          {loading && (
                            <div className="d-flex justify-content-center mt-3">
                              <span className="spinner-grow text-info spinner-grow-lg"></span>
                            </div>
                          )}
                        </div>

                        {message && (
                          <div className="form-group mt-1">
                            <div className="alert alert-danger" role="alert">
                              {message}
                            </div>
                          </div>
                        )}
                        <CheckButton
                          style={{ display: "none" }}
                          ref={checkBtn}
                        />
                        <hr />
                      </Form>

                      <a className="small ds-text-login-tb" href="#">
                        Mot de passe oublié?
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 d-none d-lg-flex">
                    <div
                      className="flex-grow-1 bg-login-image"
                      style={{ backgroundImage: `url(${bgseor})` }}
                    >
                      <span className="d-lg-flex align-items-lg-start span-arabic-text">
                        <strong>
                          <span className="text-white arabic-text-login">
                            (وَجَعَلْنَا مِنَ الْمَاءِ كُلَّ شَيْءٍ حَيٍّ)
                          </span>
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLogin />
    </div>
  );
}

export default LoginPage;
