import React, { useState, useEffect } from "react";
import "../../../assets/css/styles.min.css";
import "../../../assets/fonts/fontawesome-all.min.css";
import "../../../assets/fonts/font-awesome.min.css";
import "../styles-used.css";

function AlimentationReseauAEP() {
  return (
    <>
      <div className="container-fluid">
        <h3 className="mb-4 font-text-title">Reseau AEP</h3>
        <form>
          <div className="card shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <div className="row">
                      <div className="col-4">
                        <div className="mb-3">
                          <label className="form-label ">
                            Date Du
                            <br />
                          </label>

                          <input type="date" class="form-control" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <div className="mb-3">
                              <label className="form-label ">
                                Import ficher CSV
                                <br />
                              </label>
                              <input
                                className="form-control font-text-paragraphe"
                                type="file"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="table-responsive table mt-2"
                id="dataTable"
                role="grid"
                aria-describedby="dataTable_info"
              >
                <table className="table my-0" id="dataTable">
                  <thead>
                    <tr>
                      <th>
                        <span className="table-title">Point de production</span>
                        <br />
                      </th>
                      <th>
                        <span className="table-title">Ancien index</span>
                        <br />
                      </th>
                      <th>
                        <span className="table-title">Nouveau index</span>
                        <br />
                      </th>
                      <th>
                        <span className="table-title">Prod Journalière M3</span>
                        <br />
                      </th>
                      <th>
                        <span className="table-title">Remarque</span>
                        <br />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>

                    <tr>
                      <td>SEDEM MATAA ligne 1</td>
                      <td>953345434</td>
                      <td>873345434</td>
                      <td>345434</td>
                      <td>COMPTAGE</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-6 align-self-center">
                  <div
                    id="dataTable_length"
                    className="dataTables_length"
                    aria-controls="dataTable"
                  >
                    <label className="form-label d-flex font-text-paragraphe">
                      <select className="d-inline-block form-select form-select-sm font-text-paragraphe">
                        <option value="10" selected="">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                    <ul className="pagination">
                      <li className="page-item disabled">
                        <a className="page-link" aria-label="Previous" href="#">
                          <span aria-hidden="true">«</span>
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link " href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" aria-label="Next" href="#">
                          <span aria-hidden="true">»</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default AlimentationReseauAEP;
