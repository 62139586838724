import React, { useEffect, useState } from "react";
import "./side-menu.css";
import WLOGO from "../../../assets/img/logo-seor/w-logo-seor.png";
import MenuItem from "./MenuItem";

const menuItems = [
  {
    menuName: "Tableau de Bord",
    exact: true,
    to: "/dashboard/operateur_reseau_aep",
    iconClassName: "bi bi-speedometer2",
  },
  {
    menuName: "Réseau AEP",
    exact: true,
    to: "/reseau_aep/operateur_reseau_aep",
    iconClassName: "bi bi-diagram-3-fill",
  },
  {
    menuName: "Alimentation",
    exact: true,
    to: "/alimentation/operateur_reseau_aep",
    iconClassName: "bi bi-droplet-half",
  },
  {
    menuName: "Simulateur",
    exact: true,
    to: "/simulateur/operateur_reseau_aep",
    iconClassName: "bi bi-stack",
  },
  {
    menuName: "Contact",
    exact: true,
    to: "/contact",
    iconClassName: "bi bi-envelope",
  },
  {
    menuName: "Paramétres",
    exact: true,
    to: "/parametre",
    iconClassName: "bi bi-gear",
  },
  {
    menuName: "Déconnecter",
    exact: true,
    to: "/deconnecter",
    iconClassName: "bi bi-box-arrow-right",
  },
];

export default function SideMenu(props) {
  var date = new Date();
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    if (inactive) {
      document.querySelectorAll(".sub-menu").forEach((el) => {
        el.classList.remove("active");
      });
    }

    props.onCollapse(inactive);
  }, [inactive]);

  return (
    <div className={`side-menu ${inactive ? "inactive" : ""}`}>
      <div className="top-section ">
        <div className="logo">
          {inactive ? "" : <img src={WLOGO} alt="seor logo" />}
        </div>
        <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
          {inactive ? (
            <i class="bi bi-arrow-right-square-fill size-btn"></i>
          ) : (
            <i class="bi bi-arrow-left-square-fill size-btn"></i>
          )}
        </div>
      </div>

      <div className="search-controller">
        <button className="search-btn">
          <i class="bi bi-person-bounding-box"></i>
        </button>

        <input type="text" placeholder="Operateur Reseau AEP" disabled />
      </div>

      <div className="divider"></div>

      <div className="main-menu">
        <ul>
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              menuName={menuItem.menuName}
              exact={menuItem.exact}
              to={menuItem.to}
              subMenusName={menuItem.subMenusName || []}
              iconClassName={menuItem.iconClassName}
              onClick={() => {
                if (inactive) {
                  setInactive(false);
                }
              }}
            />
          ))}
        </ul>
      </div>

      <div className="side-menu-footer">
        <div className="user-info">
          <h4>{localStorage.getItem("role").split(`"`, 2)}</h4>
          <p>{localStorage.getItem("email")}</p>
          <p>
            {"Le " +
              ("0" + date.getDate()).slice(-2) +
              "/" +
              ("0" + (date.getMonth() + 1)).slice(-2) +
              "/" +
              date.getFullYear() +
              " - " +
              date.getHours() +
              "h : " +
              date.getMinutes() +
              "m"}
          </p>
        </div>
      </div>
    </div>
  );
}
