import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

//import AppAgentSaisie from "./AppAgentSaisie";
import AppOperateurGerance from "./AppOperateurGerance";
import AppOperateurReseauAEP from "./AppOperateurReseauAEP";
import AppAgentSaisie from "./AppAgentSaisie";
import LoginPage from "./UI/auth/LoginPage";
import Principale from "./Principale";
//import LoginPage from "./UI/auth/LoginPage";

ReactDOM.render(
  <React.StrictMode>
    <Principale/>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
