import React, { useState, useEffect } from "react";
import "../../../assets/css/styles.min.css";
import "../../../assets/fonts/fontawesome-all.min.css";
import "../../../assets/fonts/font-awesome.min.css";
import "../styles-used.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DashboardOperateurReseauAEP() {
  // Bar Chart
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: [
        "ENREGISTER",
        "EN ATTENTE",
        "PROGRAMER",
        "EN COURS",
        "TRANSFERER",
        "REGLER",
      ],
      datasets: [
        {
          label: "AEP",
          backgroundColor: "rgb(47, 115, 182, 0.7)",
          data: [2000, 10000, 5000, 15000, 10000, 20000],
        },
        {
          label: "ASS",
          backgroundColor: "rgb(118, 189, 128, 0.7)",
          data: [6000, 8000, 1200, 9000, 23000, 5000],
        },
        {
          label: "COM",
          backgroundColor: "rgb(38, 170, 224, 0.7)",
          data: [1000, 12000, 23000, 3000, 1200, 6000],
        },
      ],
    });
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
        },
      },
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex justify-content-between align-items-center mb-4">
          <h3 className=" mb-4 font-text-title">TABLEAU DE BORD</h3>
        </div>

        <div className="row">
          <div className="col-md-12 col-xl-12 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="row ">
                      <div className=" col-3">
                        <div className="text-uppercase text-primary fw-bold text-xs mb-1 align-horis-contain">
                          <span className="text-body">
                            Source d’alimentation
                          </span>
                        </div>
                      </div>
                      <div className=" col-3">
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">Nombre tolat</span>
                          <p className="font-text-title">20</p>
                        </div>
                      </div>
                      <div className=" col-3">
                        {" "}
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">
                            Sources fonctionnel
                          </span>
                          <p className="font-text-title">17</p>
                        </div>
                      </div>
                      <div className=" col-3">
                        {" "}
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">Sources en arret</span>
                          <p className="font-text-title">3</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-xl-12 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="row ">
                      <div className=" col-3">
                        <div className="text-uppercase text-primary fw-bold text-xs mb-1 align-horis-contain">
                          <span className="text-body">Point de production</span>
                        </div>
                      </div>
                      <div className=" col-3">
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">Nombre tolat</span>
                          <p className="font-text-title">22</p>
                        </div>
                      </div>
                      <div className=" col-3">
                        {" "}
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">
                            Sources fonctionnel
                          </span>
                          <p className="font-text-title">20</p>
                        </div>
                      </div>
                      <div className=" col-3">
                        {" "}
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">Sources en arret</span>
                          <p className="font-text-title">2</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-xl-12 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="row ">
                      <div className=" col-3">
                        <div className="text-uppercase text-primary fw-bold text-xs mb-1 align-horis-contain">
                          <span className="text-body">Réservoire</span>
                        </div>
                      </div>
                      <div className=" col-3">
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">Nombre tolat</span>
                          <p className="font-text-title">50</p>
                        </div>
                      </div>
                      <div className=" col-3">
                        {" "}
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">
                            Sources fonctionnel
                          </span>
                          <p className="font-text-title">44</p>
                        </div>
                      </div>
                      <div className=" col-3">
                        {" "}
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">Sources en arret</span>
                          <p className="font-text-title">6</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-xl-12 mb-4">
            <div className="card shadow border-start-primary py-2 ds-card-dash">
              <div className="card-body">
                <div className="row align-items-center no-gutters">
                  <div className="col me-2">
                    <div className="row ">
                      <div className=" col-3">
                        <div className="text-uppercase text-primary fw-bold text-xs mb-1 align-horis-contain">
                          <span className="text-body">Rues</span>
                        </div>
                      </div>
                      <div className=" col-3">
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">Nombre tolat</span>
                          <p className="font-text-title">5694</p>
                        </div>
                      </div>
                      <div className=" col-3">
                        {" "}
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">
                            Sources fonctionnel
                          </span>
                          <p className="font-text-title">5601</p>
                        </div>
                      </div>
                      <div className=" col-3">
                        {" "}
                        <div className="sub-container-reseau-aep">
                          <span className="table-title">Sources en arret</span>
                          <p className="font-text-title">693</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DashboardOperateurReseauAEP;
