import React, { useState, useEffect } from "react";
import "../../../assets/css/styles.min.css";
import "../../../assets/fonts/fontawesome-all.min.css";
import "../../../assets/fonts/font-awesome.min.css";
import "../styles-used.css";

function ReseauAEP() {
  return (
    <>
      <div className="container-fluid">
        <h3 className="mb-4 font-text-title">Reseau AEP</h3>
        <form>
          <div className="row mb-3">
            <div className="col">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label ">
                          Source d’alimentation
                          <br />
                        </label>
                        <select className="form-select font-text-paragraphe">
                          <optgroup label="This is a group">
                            <option value="12" selected="">
                              SDEM chat el Hillel-tafna
                            </option>
                            <option value="13">This is item 2</option>
                            <option value="14">This is item 3</option>
                          </optgroup>
                        </select>
                        <div className="form-check form-switch switch-ds-container">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label ">
                          Point de production
                          <br />
                        </label>
                        <select className="form-select font-text-paragraphe">
                          <optgroup label="This is a group">
                            <option value="12" selected="">
                              Rue 5000 dzioua
                            </option>
                            <option value="13">This is item 2</option>
                            <option value="14">This is item 3</option>
                          </optgroup>
                        </select>
                        <div className="form-check form-switch switch-ds-container">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label ">
                          Reservoir
                          <br />
                        </label>
                        <select className="form-select font-text-paragraphe">
                          <optgroup label="This is a group">
                            <option value="12" selected="">
                              Rue 1500 sain rock
                            </option>
                            <option value="13">This is item 2</option>
                            <option value="14">This is item 3</option>
                          </optgroup>
                        </select>
                        <div className="form-check form-switch switch-ds-container">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* code body */}
            </div>
          </div>

          <div className="card shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label ">
                            Nom de réservoir
                            <br />
                          </label>
                          <select className="form-select font-text-paragraphe">
                            <optgroup label="This is a group">
                              <option value="12" selected="">
                                réservoir 1
                              </option>
                              <option value="13">This is item 2</option>
                              <option value="14">This is item 3</option>
                            </optgroup>
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label ">
                            Etat
                            <br />
                          </label>
                          <select className="form-select font-text-paragraphe">
                            <optgroup label="This is a group">
                              <option value="12" selected="">
                                fonctionnelle
                              </option>
                              <option value="13">This is item 2</option>
                              <option value="14">This is item 3</option>
                            </optgroup>
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label ">
                            Frec d’alimentation
                            <br />
                          </label>
                          <select className="form-select font-text-paragraphe">
                            <optgroup label="This is a group">
                              <option value="12" selected="">
                                24/24
                              </option>
                              <option value="13">This is item 2</option>
                              <option value="14">This is item 3</option>
                            </optgroup>
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label ">
                            Recherche
                            <br />
                          </label>
                          <div
                            className="text-md-end dataTables_filter"
                            id="dataTable_filter"
                          >
                            <input
                              type="search"
                              className="form-control form-control-sm font-text-paragraphe"
                              aria-controls="dataTable"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="table-responsive table mt-2"
                id="dataTable"
                role="grid"
                aria-describedby="dataTable_info"
              >
                <table className="table my-0" id="dataTable">
                  <thead>
                    <tr>
                      <th>
                        <span className="table-title">REF</span>
                        <br />
                      </th>
                      <th>
                        <span className="table-title">Nom de Rue</span>
                        <br />
                      </th>
                      <th>
                        <span className="table-title">Commune</span>
                        <br />
                      </th>
                      <th>
                        <span className="table-title">Etat</span>
                        <br />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>2334</td>
                      <td>36 Logts sotragip residence </td>
                      <td>Arzew</td>
                      <td>
                        {" "}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-6 align-self-center">
                  <div
                    id="dataTable_length"
                    className="dataTables_length"
                    aria-controls="dataTable"
                  >
                    <label className="form-label d-flex font-text-paragraphe">
                      <select className="d-inline-block form-select form-select-sm font-text-paragraphe">
                        <option value="10" selected="">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                    <ul className="pagination">
                      <li className="page-item disabled">
                        <a className="page-link" aria-label="Previous" href="#">
                          <span aria-hidden="true">«</span>
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link " href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" aria-label="Next" href="#">
                          <span aria-hidden="true">»</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default ReseauAEP;
