import React from "react";
import "../../../assets/css/styles.min.css";

function Footer() {
  var date = new Date();
  return (
    <>
      <div className="bg-white sticky-footer footer-ds">
        <div className="container my-auto">
          <div className="text-center my-auto copyright">
            <span>Copyright © SEOR Algérie {date.getFullYear()}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
