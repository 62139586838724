const globals = {

    colors: {
        // main theme color
        seor_primary_blue: "#2F73B6",
        seor_secondary_bleu: "#26AAE0",
        seor_primary_green: "#76BD80",

        // ui used color
        highlight: "#F5C41D",
        info: "#2F73B6",
        alert: "#E00B19",
        success : "#00AA10",
        black_fonts: "#282828",
        black: "#000",
        white: "#FFF",
        formWhite: "#F0F1F2",
        formGrey: "#B0B3B4",
    },
    fonts: {},
};

export default globals;
