import React from "react";
import "../../../assets/css/styles.min.css";
import "../styles-used.css";

function ResponsableOperateurGerance() {
  return (
    <>
      <div className="container-fluid">
        <h3 className="mb-4 font-text-title">Respancable</h3>
        <div className="row mb-3">
          <div className="col-lg-8">
            <div className="row">
              <div className="col">
                <div className="card shadow mb-3">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col col-8">
                          <div className="row">
                            <div className="col">
                              <div className="mb-3">
                                <label className="table-title">
                                  Identifiant
                                  <br />
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="identifiant"
                                  placeholder="identifiant"
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="mb-3">
                                <label className="table-title">
                                  Responsable
                                  <br />
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="responsable"
                                  placeholder="responsable"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="mb-3">
                                <label className="table-title">
                                  Gerence
                                  <br />
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="gerance"
                                  placeholder="gerance"
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="mb-3">
                                <label className="table-title">
                                  Fonction
                                  <br />
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="fonction"
                                  placeholder="fonction"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="mb-3">
                                <label className="table-title">
                                  N° de téléphone
                                  <br />
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="telephone"
                                  placeholder="n° telephon"
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="mb-3">
                                <label className="table-title">
                                  Email
                                  <br />
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="email"
                                  placeholder="email"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row d-xxl-flex">
                            <div className="col d-xxl-flex justify-content-xxl-center col-12">
                              <div className="mb-3">
                                <img className="rounded d-xl-flex mb-3 mt-4 img-avatar-cat" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-xl-flex">
              <div className="row d-xl-flex">
                <div className="col d-xl-flex col-12 colm-pad-ds">
                  <button
                    className="btn btn-success text-center link-light border rounded d-lg-flex justify-content-lg-center align-items-lg-center btn-ds-op btn-ds font-text-paragraphe mb-3"
                    type="button"
                  >
                    Ajouter un responsable
                    <br />
                    <i className="fa fa-plus-circle d-lg-flex align-items-lg-center icon-btn-ds"></i>
                  </button>
                </div>
                <div className="col col-12 colm-pad-ds">
                  <button
                    className="btn btn-primary text-center border rounded d-lg-flex justify-content-lg-center align-items-lg-center btn-ds-op btn-ds font-text-paragraphe mb-3"
                    type="button"
                  >
                    Modifier ce responsable
                    <br />
                    <i className="fa fa-pencil-square icon-btn-ds"></i>
                  </button>
                </div>
                <div className="col col-12 colm-pad-ds">
                  <button
                    className="btn btn-danger text-center border rounded d-lg-flex justify-content-lg-center align-items-lg-center btn-ds-op btn-ds font-text-paragraphe mb-3"
                    type="button"
                  >
                    Suprimer ce responsable
                    <br />
                    <i className="fa fa-trash icon-btn-ds"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 text-nowrap">
                <div
                  className="text-md-end dataTables_filter"
                  id="dataTable_filter"
                >
                  <input
                    type="search"
                    className="form-control form-control-sm font-text-paragraphe"
                    aria-controls="dataTable"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <div className="row">
                    <div className="col">
                      <button
                        className="btn btn-secondary text-center border rounded d-lg-flex justify-content-lg-center align-items-lg-center btn-ds-op btn-ds font-text-paragraphe"
                        type="button"
                      >
                        Filtre<i className="fa fa-filter icon-btn-ds"></i>
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-info link-light border rounded d-lg-flex justify-content-lg-center align-items-lg-center btn-ds-op btn-ds font-text-paragraphe"
                        type="button"
                      >
                        Imprimer<i className="fa fa-print icon-btn-ds"></i>
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-success link-light d-lg-flex justify-content-lg-center align-items-lg-center btn-ds-op btn-ds font-text-paragraphe"
                        type="button"
                      >
                        Ajouter
                        <i className="fa fa-plus-circle d-lg-flex align-items-lg-center icon-btn-ds"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="table-responsive table mt-2"
              id="dataTable"
              role="grid"
              aria-describedby="dataTable_info"
            >
              <table className="table my-0" id="dataTable">
                <thead>
                  <tr>
                    <th>
                      <span className="table-title">Identifiant</span>
                    </th>
                    <th>
                      <span className="table-title">ID reclamation</span>
                    </th>
                    <th>
                      <span className="table-title">Responsible</span>
                    </th>
                    <th>
                      <span className="table-title">Type de travaux&nbsp;</span>
                    </th>
                    <th>
                      <span className="table-title">Date debut</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                  <tr>
                    <td>6776458</td>
                    <td>56584</td>
                    <td>Nom responsable</td>
                    <td>AEP</td>
                    <td>10-10-2022</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-md-6 align-self-center">
                <div
                  id="dataTable_length"
                  className="dataTables_length"
                  aria-controls="dataTable"
                >
                  <label className="form-label d-flex">
                    Line&nbsp;&nbsp;
                    <select className="d-inline-block form-select form-select-sm">
                      <option value="10" selected="">
                        10
                      </option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                  <ul className="pagination">
                    <li className="page-item disabled">
                      <a className="page-link" aria-label="Previous" href="#">
                        <span aria-hidden="true">«</span>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" aria-label="Next" href="#">
                        <span aria-hidden="true">»</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResponsableOperateurGerance;
