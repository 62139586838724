export default global.config = {
    apiUrl: {
        reclamation: {
            link: "https://seorrr.herokuapp.com/reclamations",
        },
        canalEnregistrements: {
            link: "https://seorrr.herokuapp.com/canalEnregistrements/",
        }
        // rest of links or object
    }
    // other global config variables
};

