import axios from "axios";

const API_URL = process.env.HOST;

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const islogged = () => {
  const token = localStorage.getItem("role");
  return !!token;
};

const login = (email, password) => {
  return axios
    .post("https://seorrr.herokuapp.com/auth/signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("role", JSON.stringify(response.data.role));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", email);
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("role");
  return axios.post(API_URL + "signout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("role"));
};

// export function !
const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  islogged,
};
export default AuthService;
