import React from "react";
import "../../../assets/css/styles.min.css";
import "../styles-used.css";

function GestionReclamationOperateurGerance() {
  return (
    <>
      <div className="container-fluid">
        <h3 className="text-dark mb-4">
          <span>Gérer une réclamation</span>
        </h3>
        <div>
          <div className="row">
            <div className="col">
              <div className="card shadow mb-3">
                <div className="card-body">
                  <div className="row d-xl-flex">
                    <div className="col">
                      <button
                        className="btn btn-info link-light border rounded d-lg-flex justify-content-lg-center align-items-lg-center btn-ds-op btn-ds font-text-paragraphe"
                        type="button"
                      >
                        Imprimer<i className="fa fa-print icon-btn-ds"></i>
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-success link-light border rounded d-lg-flex align-items-xl-center btn-ds-op2 btn-ds font-text-paragraphe"
                        type="button"
                      >
                        Programer des travaux
                        <br />
                        <i className="fa fa-calendar d-lg-flex align-items-lg-center icon-btn-ds"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-3">
                <div className="card-body">
                  <form>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              N° de réclamation&nbsp;
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Nom / Raison S<br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Date de réclamation
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              N° contrat
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Type de réclamation
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Adresse
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Direction concerné
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Rue
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Observation
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Commune
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              N° de téléphone
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Bloc
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Email
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Entree
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Niveau urgence&nbsp;
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Etage
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Responsable
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              Ail
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows-ds">
                      <div className="col"></div>
                      <div className="col">
                        <div className="row">
                          <div className="col col-4">
                            <label className="col-form-label d-lg-flex label-ds">
                              N° Dom
                              <br />
                            </label>
                          </div>
                          <div className="col col-8">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card shadow mb-3">
                <div className="card-body card-bd-m">
                  <div className="row d-xl-flex">
                    <div className="col col-4">
                      <label className="font-text-paragraphe-bg">
                        Etat de la reclamation&nbsp;
                      </label>
                    </div>
                    <div className="col d-xl-flex align-items-xl-center col-8">
                      <span className="font-text-paragraphe-bg badge-success">
                        Enregistrer
                        <br />
                      </span>
                    </div>
                  </div>
                  <div className="row d-xl-flex">
                    <div className="col col-4">
                      <span className="font-text-paragraphe-bg">Travaux</span>
                    </div>
                    <div className="col col-8">
                      <span className="font-text-paragraphe-bg badge-info">
                        Pas de travaux programmer
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GestionReclamationOperateurGerance;
