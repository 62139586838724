//import React, { lazy, Suspense } from "react";
import { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme/themes";
//import Footer from "./UI/base/footer/footer";
//Privet route
//Public route
import ScrollToTop from "./UI/other/ScrollToTop";
// success model valid
//import { useStorage } from "./context/useStorage";
import { Toaster } from "react-hot-toast";
import SideMenuOperateurGerance from "./UI/base/Slidebarre/SideMenuOperateurGerance";
import Footer from "./UI/base/footer/footer";

// added
import LoginPage from "./UI/auth/LoginPage";
import Deconnecter from "./UI/auth/Logout";
import DashboardOperateurGerance from "./UI/component/operateur_gerance/DashboardOperateurGerance";
import ReclamationListOperateurGerance from "./UI/component/operateur_gerance/ReclamationListOperateurGerance";
import ReclamationAvancement from "./UI/component/operateur_gerance/ReclamationAvancement";
import GestionReclamationOperateurGerance from "./UI/component/operateur_gerance/GestionReclamationOperateurGerance";
import ResponsableOperateurGerance from "./UI/component/operateur_gerance/ResponsableOperateurGerance";
// for test

const Contact = () => {
  return <h1>Contact</h1>;
};

const Parametre = () => {
  return <h1>Parametre</h1>;
};

function AppOperateurGerance() {
  //const { isInitialized } = useStorage();
  const [inactive, setInactive] = useState(false);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <Toaster />
          {/* <Switch>
            <Route
              path="/auth/login"
              exact
              component={() => (
                <>
                  <LoginPage />
                </>
              )}
            />
          </Switch> */}

          <div className="row">
            <div className="col-2">
              <SideMenuOperateurGerance
                onCollapse={(inactive) => {
                  setInactive(inactive);
                }}
              />
            </div>
            <div className="col-10">
              <div className={`container ${inactive ? "inactive" : ""}`}>
                <Switch>
                  <Route
                    path="/dashboard/operateur_gerance"
                    exact
                    component={() => (
                      <>
                        <DashboardOperateurGerance />
                      </>
                    )}
                  />

                  <Route
                    path="/dashboard/operateur_gerance"
                    exact
                    component={() => (
                      <>
                        <DashboardOperateurGerance />
                      </>
                    )}
                  />

                  <Route
                    path="/reclamations/list/operateur_gerance"
                    exact
                    component={() => (
                      <>
                        <ReclamationListOperateurGerance />
                      </>
                    )}
                  />

                  <Route
                    path="/reclamations/avancement/operateur_gerance"
                    exact
                    component={() => (
                      <>
                        <ReclamationAvancement />
                      </>
                    )}
                  />

                  <Route
                    path="/reclamations/gestion_reclamation/operateur_gerance"
                    exact
                    component={() => (
                      <>
                        <GestionReclamationOperateurGerance />
                      </>
                    )}
                  />

                  <Route
                    path="/responsable/operateur_gerance"
                    exact
                    component={() => (
                      <>
                        <ResponsableOperateurGerance />
                      </>
                    )}
                  />

                  <Route
                    path="/contact/operateur_gerance"
                    exact
                    component={() => (
                      <>
                        <Contact />
                      </>
                    )}
                  />
                  <Route
                    path="/parametre/operateur_gerance"
                    exact
                    component={() => (
                      <>
                        <Parametre />
                      </>
                    )}
                  />
                  <Route
                    path="/deconnecter"
                    exact
                    component={() => (
                      <>
                        <Deconnecter />
                      </>
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default AppOperateurGerance;
