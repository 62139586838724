import React, { useState, useEffect, useRef } from "react";
import "../../../assets/css/styles.min.css";
import "../styles-used.css";
import CLOGO from "../../../assets/img/logo-seor/color-logo-seor.png";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "../../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, {
  selectFilter,
  textFilter,
} from "../../../../node_modules/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter";
import "../../../utils/globalConfig";

function ReclamationAvancement() {

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    
  });

  const [data, setData] = useState([]);
  const url = global.config.apiUrl.reclamation.link;

  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(url, config).then((response) => {
      setData(response.data);
    });
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total text-pagination">
      Affichage de {from} a {to} sur {size} Résultats
    </span>
  );

  // Array options
  const selectOptionsArr = [
    {
      value: "ACTIVE",
      label: "ACTIVE",
    },
    {
      value: "EN COURS",
      label: "EN COURS",
    },
    {
      value: "ENREGISTRER",
      label: "ENREGISTRER",
    },
  ];

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "N° Reclamation",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "TypeReclmation.intitule",
      text: "Type de réclamation",
      filter: textFilter(),
    },
    {
      dataField: "",
      text: "Secteur",
      filter: textFilter(),
    },
    {
      dataField: "",
      text: "Urgence",
      filter: textFilter(),
    },
    {
      dataField: "etatReclamation",
      text: "Etat",
      filter: selectFilter({
        options: selectOptionsArr,
      }),
    },
    {
      dataField: "",
      text: "Délai",
      filter: textFilter(),
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <>

      <div ref={componentRef} className="print-page-style">
        <div className="row pb-5">
          <div className="col-6 md-12">
            <img src={CLOGO} className="logo-print" alt="seor logo" />
          </div>
          <div className="col-6 md-12"></div>
        </div>

        <div className="row pb-3">
          <div className="col-6 md-12">
            <span className="titre-print">{`Numero de contrat :  ${row.Contrat.numContrat}`}</span>
            <div className="alert alert-primary">
            <p>
              {`Canal Enregistrement :  ${row.CanalEnregistrement.intitule}`}
              <br />

              {`Type Reclmation :  ${row.TypeReclmation.intitule}`}
            </p>
</div>
           
          </div>
          <div className="col-6 md-12">
            <p>
              <span className="titre-print">Information du client</span>
              <br />
              {`Nom client :  ${row.Contrat.nom}`}
              <br />
              {`Téléphone :  ${row.Contrat.telephone}`}
              <br />
              {`Adresse :  rue ${row.Contrat.rue}, bloc ${row.Contrat.bloc}, Entree ${row.Contrat.entree}, Etage : ${row.Contrat.etage} ${row.Contrat.commune}`}
              <br />
              {`Entree :  ${row.Contrat.entree}`}
            </p>
          </div>
        </div>

        <div className="pb-3">
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. 
            <br/>
            Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years, sometimes by accident, sometimes on purpose injected humour
            and the like.
          </p>
        </div>
        <div className="row">
          <div className="col-6 md-12">
          <p>
          {`La réclamation a ete créé le  :  ${(row.dateReclmation).split('T')[0]} à ${((row.dateReclmation).split('T')[1]).split('.')[0]}`}
          <br/>
          {`Source :  ${row.Source.intitule}`}
          <br/>
          {`Commentaire :  ${row.commentaire}`}
          <br/>
          {`Observation :  ${row.observation}`}
          <br/>
          
          {`L'etat de la réclamation :  ${row.etatReclamation}`}
          </p>
          </div>
          <div className="col-6 md-12">
            <p>
            {`Téléphone client :  ${row.telephone}`}
            <br/>
            {`Email client :  ${row.email}`}
            </p>
          </div>

          <div className="footer-print mt-5"><p>Il est imprimé à partir de la plate-forme soar</p></div>
        </div>

        
      </div>
      <div className="print-page-style layout-print-btn"><button type="button" className="btn btn-print" onClick={handlePrint}>
      Imprimer la page
        </button></div>
      </>
    ),
    showExpandColumn: true,
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-light"}`}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const options = {
    sizePerPageRenderer,
    firstPageText: "Première",
    prePageText: "Retour",
    nextPageText: "suivant",
    lastPageText: "Dernier",
    nextPageTitle: "Première page",
    prePageTitle: "Pré-page",
    firstPageTitle: "Page suivante",
    lastPageTitle: "Dernière page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "Tout",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <h3 className="mb-4 font-text-title">Réclamations avancement</h3>
        <div className="card shadow">
          <div className="card-body">
            <div
              className="table-responsive table mt-2"
              id="dataTable"
              role="grid"
              aria-describedby="dataTable_info"
            >
              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <div>
                    <SearchBar {...props.searchProps} />

                    <hr />
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      striped
                      hover
                      condensed
                      noDataIndication="Aucun résultat n'existe ou une problem de connection internet !"
                      filter={filterFactory()}
                      filterPosition="buttom"
                      defaultSorted={defaultSorted}
                      expandRow={expandRow}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReclamationAvancement;
